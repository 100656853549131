<template>
   <Toolbar class="mb-4">
      <template #end>
         <router-link to="/doctor"
            ><Button
               label="Back"
               icon="pi pi-arrow-left"
               class="p-button-success mr-2 p-button-sm"
         /></router-link>
      </template>
   </Toolbar>
   <div class="grid">
      <div class="col-6">
         <div class="card">
            <Fieldset legend="Doctor Detail">
               <div>
                  <span><b>ID</b></span> : {{ doctor.id }}
               </div>
               <Divider />
               <div>
                  <span><b>Name</b></span> : {{ doctor.name }}
               </div>
               <Divider />
               <div>
                  <span><b>Email</b></span> : {{ doctor.email }}
               </div>
               <Divider />
               <div>
                  <span><b>Status</b></span> : {{ doctor.status }}
               </div>
               <Divider />
               <div>
                  <span><b>Current Balance</b></span> :
                  {{ doctor.total_balance }}
               </div>
               <Divider />
               <div>
                  <span><b>Nrc Number</b></span> : {{ doctor.nrc
                  }}{{ doctor.nrc_citizen }}{{ doctor.nrc_number }}
               </div>
               <Divider />
               <div>
                  <span><b>Specialist</b></span> : {{ doctor.specialist }}
               </div>
               <Divider />
               <div>
                  <span><b>SAMA Number</b></span> : {{ doctor.sama_number }}
               </div>
               <Divider />
               <div>
                  <span><b>Region/Township</b></span> : {{ doctor.state }} /
                  {{ doctor.township }}
               </div>
               <Divider />
               <div>
                  <span><b>Degree</b></span> : {{ doctor.degree }}
               </div>
               <Divider />
               <div>
                  <span><b>Other Degree</b></span> : {{ doctor.other_degree }}
               </div>
               <Divider />
               <div>
                  <span><b>Birthdate</b></span> : {{ doctor.birth_date }}
               </div>
               <Divider />
               <div>
                  <span><b>Address</b></span> : {{ doctor.address }}
               </div>
               <Divider />
               <div>
                  <span><b>Gender</b></span> : {{ doctor.gender }}
               </div>
               <Divider />
               <div>
                  <span><b>Description</b></span> : {{ doctor.description }}
               </div>
               <Divider />
            </Fieldset>
         </div>
      </div>
      <div class="col-6">
         <div class="card">
            <Fieldset legend="Doctor Packages">
               <DataTable :value="doctor.packages" responsiveLayout="scroll">
                  <Column field="duration" header="Duration (Min)"></Column>
                  <Column field="amount" header="Price (MMK)"></Column>
               </DataTable>
            </Fieldset>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
   data() {
      return {
         doctor: {},
         loading: true,
      };
   },
   methods: {
      ...mapActions(["doctor/show"]),

      showDoctor() {
         this["doctor/show"](this.$route.params.id)
            .then((response) => {
               this.doctor = response;
               this.loading = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
   },
   created() {
      this.showDoctor();
   },
};
</script>

<style lang="scss" scoped>
.table-header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 960px) {
      align-items: start;
   }
}
</style>
